@tailwind base;
@tailwind components;
@tailwind utilities;


.hero-section {
    position: relative;
  }
  
  .slide {
    position: relative;
  }
  
  .slide img {
    width: 100%;
    height: auto;
  }
  
  .text-overlay {
    position: absolute;
    top: 17%;
    left: 3%;
    color: white; /* Yazı rengini ihtiyaca göre değiştirebilirsiniz */
    font-size: 4rem; /* Yazı boyutunu ihtiyaca göre değiştirebilirsiniz */
    z-index: 1; /* Üstteki elemanları göstermek için */
  }
  
  .text-overlay h1 {
    margin: 0;
  }
  

  /* HeroSection.css */

  .hamburger-color {
    background-color: #3533cd;
  }


  



  

  